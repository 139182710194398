import { useContext } from "react"
import { EditorContext } from "../../../editor"
import { FaTrashAlt } from "react-icons/fa"

const DeleteTool = () =><></>
const Button = () => {
    const { editor  } = useContext(EditorContext)
    const canvas = editor?.canvas
    return <button
        onClick={() => {
            if (!canvas) { return }
            editor?.canvas?.remove(editor?.canvas?.getActiveObject())

        }}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${canvas?.isDrawingMode && "bg-gray-100"}`}>
        <FaTrashAlt className="text-lg"/>
    </button>
}
DeleteTool.Button = Button
export default DeleteTool