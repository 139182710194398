import { useContext, useState } from "react"
import { EditorContext } from "../../../editor"
import { BiFileBlank } from "react-icons/bi"
import ConfirmModal from "./popup"

const CreateTool = () =><></>
const Button = () => {
    const { editor  } = useContext(EditorContext)
    const canvas = editor?.canvas
    const [isModelOpen , setIsModelOpen] = useState(false)
    return <><ConfirmModal isOpen={isModelOpen} setIsOpen={setIsModelOpen} editor={editor} /> <button
    onClick={() => {
        setIsModelOpen(true)

    }}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${canvas?.isDrawingMode && "bg-gray-100"}`}>
        <BiFileBlank className="text-lg"/>
    </button></>
}
CreateTool.Button = Button
export default CreateTool